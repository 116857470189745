<template>
    <div class="page">
        <div v-if="detailTitle">
            <div class="bread-crumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item @click.native="change">{{detailTitle}}</el-breadcrumb-item>
                    <el-breadcrumb-item>详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="box" v-if="pageType==2||pageType==3">
                <h2 v-if="pageType==2">{{detailObj.title}}</h2>
                <h2 v-if="pageType==3">{{detailObj.title}}</h2>
                <ul>
                    <li v-if="detailObj.author||detailObj.source">作者：{{detailObj.author?detailObj.author:(detailObj.source?detailObj.source:'来源')}}   </li>
                    <li v-if="detailObj.releaseTime">    发布时间：{{detailObj.releaseTime.substring(0,10)}}</li>
                    <li v-if="detailObj.lookNum||lookNum">浏览量：{{detailObj.lookNum?detailObj.lookNum:lookNum}}</li>
                </ul>
                <el-table v-if="pageType==2" :data="detailObj.list" :span-method="objectSpanMethod" border style="width: 100%; margin-top: 20px">
                    <el-table-column prop="work" label="星期" width="180"></el-table-column>
                    <el-table-column prop="time" label="时间">
                        <template slot-scope="scope">
                            <div v-for="(val,index) in scope.row.time" :key="index">
                                <div>{{val+'\n'}}</div>
                              </div>
                        </template>
</el-table-column>
<el-table-column prop="details" label="内容">
    <template slot-scope="scope">
        <div v-for="(val,index) in scope.row.details" :key="index">
            <div>{{val+'\n'}}</div>
          </div>
    </template>
</el-table-column>
<el-table-column prop="place" label="地点">
    <template slot-scope="scope">
        <div v-for="(val,index) in scope.row.place" :key="index">
            <div>{{val+'\n'}}</div>
          </div>
    </template>
</el-table-column>
<el-table-column prop="deptName" label="负责人/部门">
    <template slot-scope="scope">
        <div v-for="(val,index) in scope.row.deptName" :key="index">
            <div>{{val+'\n'}}</div>
          </div>
    </template>
</el-table-column>
</el-table>
<el-table v-if="pageType==3" :data="detailObj.list" border style="width: 100%; margin-top: 20px">
    <el-table-column prop="week" label="星期" width="180"></el-table-column>
    <el-table-column prop="name" label="早餐食谱"> </el-table-column>
    <el-table-column prop="food" label="午餐食谱"> </el-table-column>
    <el-table-column prop="soup" label="午点"> </el-table-column>
</el-table>
</div>
<div class="box" v-else>
    <h2>{{detailObj.title}}</h2>
    <ul>
        <li v-if="detailObj.author||detailObj.source">作者：{{detailObj.author?detailObj.author:(detailObj.source?detailObj.source:'来源')}}</li>
        <li v-if="detailObj.createTime">发布时间：{{detailObj.createTime.substring(0,10)}}</li>
        <li v-if="detailObj.lookNum||lookNum">浏览量：{{detailObj.lookNum?detailObj.lookNum:lookNum}}</li>
    </ul>
    <p v-if="detailObj.content" v-html="detailObj.content"></p>
    <div class="detail-img" v-else><img :src="detailObj.path" alt=""></div>
    <template v-if="detailObj.fileList">
        <div v-for="item of detailObj.fileList" :key="item.id">
            <a :href="item.address">{{item.name}}</a>
        </div>
    </template>
    <!-- <a v-if="detailObj.uploadFiles" :href="detailObj.uploadFiles">{{detailObj.uploadFiles}}</a> -->
</div>
</div>
<div v-else>
    <div class="list" v-for="(item) of conList" @click="toChange(item.type,item)" :key="item.id">
        <p>{{item.title}}</p>
        <ul>
            <!-- <li># {{item.author?item.author:'来源'}}</li> -->
            <!-- &&item.source -->
            <li># {{item.author?item.author:(item.source?item.source:'来源')}}</li>
            <li>{{item.createTime.substring(0,10)}}</li>
        </ul>
    </div>
</div>
</div>
</template>
<script>
    export default {
        props: ['conList', 'tableList', 'type', 'detailObj', 'lookNum'],
        data() {
            return {
                detailTitle: '',
                pageType: 0,
                spanArr: [],
            }
        },
        watch: {
            type(val, oldVal) {
                if (val != oldVal) {
                    this.detailTitle = '';
                }
            }
        },
        methods: {
            objectSpanMethod({
                row,
                column,
                rowIndex,
                columnIndex
            }) {},
            toChange(type, item) {
                if (this.type == '10') {
                    this.detailTitle = item.title;
                } else {
                    if (type) {
                        this.detailTitle = this.tableList[type - 1];
                    } else {
                        this.detailTitle = this.tableList[this.type];
                    }
                }
                this.pageType = item.type;
                if (item.type == 2 || item.type == 3) {
                    this.detailTitle = ''
                }
                this.$parent.getDetail(item.id, item);
            },
            change() {
                this.detailTitle = '';
                this.$parent.change(1, 'list');
            },
        }
    }
</script>
<style scoped lang="scss">
    table {
        width: 100%;
        font-size: 16px;
        border: 1px solid #ddd;
        border-collapse: collapse;
    }
    
    table th {
        padding: 8px 0;
        box-sizing: border-box;
    }
    
    table td {
        border: 1px solid #ddd;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 8px 0;
        box-sizing: border-box;
    }
    
    @media screen and (max-width: 750px) {
        .box ::v-deep table {
            table-layout: fixed;
            width: 100% !important;
        }
        .box ::v-deep table td {
            width: auto !important;
        }
    }
    
    .bread-crumb {
        cursor: pointer;
    }
    
    .list {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        cursor: pointer;
        p {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            position: relative;
            padding-left: 15px;
            margin-bottom: 10px;
            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #050000;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
            }
        }
        ul {
            display: flex;
            li:first-child {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
                margin-right: 30px;
            }
            li:nth-child(2) {
                font-size: 14px;
                font-family: Georgia-Bold, Georgia;
                font-weight: bold;
                color: #999999;
            }
        }
    }
    
    .box {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        h2 {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            text-align: center;
            margin-bottom: 10px;
        }
        ul {
            display: flex;
            justify-content: center;
            li {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
                margin-right: 8px;
            }
            li:last-child {
                margin-right: 0;
            }
        }
        p {
            margin: 20px auto 0;
        }
    }
    
    .box p ::v-deep table {
        margin: 10px auto 0;
    }
    
    .detail-img img {
        display: block;
        margin: auto;
    }
</style>