<template>
    <div class="page">
        <div>
            <div class="bread-crumb">
                <el-breadcrumb v-if="detailType&&detailType=='search'" separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item @click.native="change">{{detailTitle1}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{detailTitle?detailTitle:'详情'}}</el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb v-else separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item @click.native="change">{{detailTitle}}</el-breadcrumb-item>
                    <el-breadcrumb-item>详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="box">
                <h2 v-if="detailObj.title">{{detailObj.title}}</h2>
                <h3 v-if="detailObj.subtitle">{{detailObj.subtitle}}</h3>
                <ul>
                    <li v-if="detailObj.author">作者：{{detailObj.author}}</li>
                    <li v-if="detailObj.createTime">发布时间：{{detailObj.createTime.substring(0,10)}}</li>
                    <li v-if="detailObj.lookNum||lookNum||lookNum==0">浏览量：{{detailObj.lookNum?detailObj.lookNum:lookNum}}</li>
                </ul>
                <p v-html="detailObj.content"></p>
                <template v-if="detailObj.fileList">
                    <div v-for="item of detailObj.fileList" :key="item.id">
                        <a :href="item.address">{{item.name}}</a>
                    </div>
                </template>
<!-- <a v-if="detailObj.uploadFiles" :href="detailObj.uploadFiles">{{detailObj.uploadFiles}}</a> -->
</div>
</div>

</div>
</template>
<script>
    export default {
        props: ['detailTitle', 'detailTitle1', 'detailObj', 'detailType', 'lookNum'],
        methods: {

        }
    }
</script>
<style scoped lang="scss">
    .bread-crumb {
        cursor: pointer;
    }
    
    .list {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        cursor: pointer;
        p {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            position: relative;
            padding-left: 15px;
            margin-bottom: 10px;
            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #050000;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
            }
        }
        ul {
            display: flex;
            li:first-child {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
                margin-right: 30px;
            }
            li:nth-child(2) {
                font-size: 14px;
                font-family: Georgia-Bold, Georgia;
                font-weight: bold;
                color: #999999;
            }
        }
    }
    
    .box {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        h2 {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #050000;
            text-align: center;
            margin-bottom: 10px;
        }
        h3 {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 400;
            color: #050000;
            text-align: right;
            margin-bottom: 10px;
        }
        ul {
            display: flex;
            justify-content: center;
            li {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #186EC5;
                margin-right: 8px;
            }
            li:last-child {
                margin-right: 0;
            }
        }
        p {
            margin: 20px auto 0;
        }
    }
    
    .box p ::v-deep img {
        max-width: 100%;
    }
    .box p ::v-deep table{
        max-width: 100% !important;
        table-layout: fixed; 
    }
    .box p ::v-deep a {
        word-break: break-all;
    }
    
    @media screen and (max-width: 700px) {
        .box p ::v-deep a {
            font-size: .47rem;
        }
        .box p ::v-deep img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: auto;
        }
    }
</style>