<template>
    <div class="know">
        <Swiper></Swiper>
      <!-- <div class="top">
        <img src="../assets/img/know-top.png" alt="">
      </div> -->
      <div class="know-main">
            <div class="know-left"></div>
            <div class="know-right">
                <div class="know-title">
                    <span>{{type?detailTitle1:'搜索结果'}}</span>
                    <img src="../assets/img/know-right-title.png" alt="">
                </div>
                <Guide v-if="content" :type="'10'" :lookNum="lookNum" :detailObj="detailObj" :conList="conList"></Guide>
                <!-- 嘉园动态 -->
                <Detail v-if="id" :lookNum="lookNum" :detailTitle1="detailTitle1" :detailType="'search'" :detailObj="detailObj" :detailTitle="detailTitle"></Detail>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                :page-sizes="[3, 6, 9, 12]" :current-page.sync="pageNum" v-if="pageShow" :page-size="pageSize" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
                <!-- <el-pagination :page-size="pageSize" :page-sizes="[3, 6, 9, 12]" @current-change="handleCurrentChange" v-if="pageShow" layout="prev, pager, next" :total="total"></el-pagination> -->
            </div>
        </div>
    </div>
  </template>
<script>
    import Swiper from '../components/Swiper.vue'
    import Guide from '../components/Information/Index.vue'
    import Detail from '../components/Information/Detail.vue'
    import {
        dimSearch,
        dimSearchDetial,
        jiaYuanDynamicDetail, //嘉园动态
        noticeDetail, //通知公告
        educationPlaceDetail, //学生活动
        teachResearchDetail, //教学科研
        indexPushDetail,
        increamPageView,
        getPageViewByNameAndId
    } from "@/request/api";
    export default {
        components: {
            Swiper,
            Guide,
            Detail
        },
        data() {
            return {
                conList: [],
                content: '',
                id: '',
                type: '',
                newTid: '',
                pageNameView: 10,
                detailObj: {},
                detailTitle: '',
                lookNum: '',
                // 分页
                total: 0,
                pageNum: 1,
                pageSize: 6,
                pageShow: true
            }
        },
        created() {
            if (this.$route.query.id) {
                this.id = this.$route.query.id;
                this.type = this.$route.query.type;
                this.newTid = this.$route.query.newTid;
                this.pageShow = false;
                if (this.type == 1) this.pageNameView = this.initTableList(this.$route.query.tid);
                if (this.type == 3) this.pageNameView = 17;
                if (this.type == 4) this.pageNameView = 22;
                if (this.type != 1 && this.type != 2) this.newTid = this.$route.query.id;
                if (this.type == 2) {
                    this.pageNameView = 13; //通知公告 
                    this.newTid = this.id
                }
                // 校园动态其他
                if (!this.$route.query.newTid && this.type == 1) {
                    this.newTid = this.$route.query.id;
                    this.pageNameView = 10;
                }
                this.getPageViewByNameAndId();
                this.toDetail()
            } else {
                this.content = this.$route.query.content;
                this.dimSearch();
            }

        },
        watch: {
            $route: {
                handler(val, oldval) {
                    this.total = 0;
                    this.pageNum = 1;
                    this.pageSize = 6;
                    this.content = val.query.content;
                    this.dimSearch();
                },
                // 深度观察监听
                deep: true
            },
        },

        methods: {
            getDetail(id) {
                this.dimSearchDetial(id);
            },
            change() {
                this.pageShow = true;
            },
            // 搜索
            dimSearch() {
                const _this = this;
                let data = {
                    content: _this.content,
                    pageNum: _this.pageNum,
                    pageSize: _this.pageSize
                }
                dimSearch(data).then(res => {
                    _this.conList = res.rows;
                    _this.total = res.total;
                    if (_this.total == 0) _this.pageShow = false;
                    else _this.pageShow = true;
                    for (let item of _this.conList) {
                        item.id = item.id + ',' + item.urlType;
                    }
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            getPageViewByNameAndId() {
                const _this = this;
                let data = {
                    pageId: _this.newTid,
                    pageName: _this.pageNameView,
                }
                getPageViewByNameAndId(data).then(res => {
                    _this.lookNum = res.data;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.dimSearch();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.dimSearch();
            },
            // 搜索详情
            dimSearchDetial(content) {
                const _this = this;
                let arr = content.split(',')
                let data = {
                    id: arr[0],
                    urlType: arr[1]
                }
                dimSearchDetial(data).then(res => {
                    _this.detailObj = res.data;
                    _this.pageShow = false;
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            toDetail() {
                if (this.type == 1) {
                    this.detailTitle1 = '校园动态';
                    if (this.$route.query.tid) this.indexPushDetail(this.$route.query.tid)
                    else this.jiaYuanDynamicDetail();
                }
                if (this.type == 2) {
                    this.detailTitle1 = '通知公告';
                    this.noticeDetail();
                }
                if (this.type == 3) {
                    this.detailTitle1 = '学生活动';
                    this.educationPlaceDetail();
                }

                if (this.type == 4) {
                    this.detailTitle1 = '教育教学';
                    this.teachResearchDetail();
                }
            },
            initTableList(type) {
                if (type == 1) return 4;
                if (type == 2) return 29;
                if (type == 3) return 31;
                if (type == 4) return 32;
                if (type == 5) return 10;
                if (type == 6) return 13;
                if (type == 7) return 17;
                if (type == 8) return 22;
            },
            jiaYuanDynamicDetail() {
                const _this = this;
                let data = {
                    id: _this.id
                };
                jiaYuanDynamicDetail(data).then(res => {
                    _this.detailObj = res;
                    // _this.getPageViewByNameAndId();
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            noticeDetail() {
                const _this = this;
                let data = {
                    id: _this.id
                };
                noticeDetail(data).then(res => {
                    _this.detailObj = res.data;
                    // _this.getPageViewByNameAndId();
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            educationPlaceDetail() {
                const _this = this;
                let data = {
                    id: _this.id
                };
                educationPlaceDetail(data).then(res => {
                    _this.detailObj = res;
                    // _this.getPageViewByNameAndId();
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            teachResearchDetail() {
                const _this = this;
                let data = {
                    id: _this.id
                };
                teachResearchDetail(data).then(res => {
                    _this.detailObj = res;
                    // _this.getPageViewByNameAndId();
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            },
            indexPushDetail(type) {
                const _this = this;
                let data = {
                    id: _this.id,
                    type: type
                };
                indexPushDetail(data).then(res => {
                    _this.detailObj = res.data;
                    _this.detailTitle = _this.detailObj.type;
                    // _this.getPageViewByNameAndId();
                }).catch((error) => {
                    // error
                    console.log(error)
                })
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "../../public/css/frame.scss";
</style>